import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, A11y, EffectCube, Autoplay } from "swiper";
import "swiper/swiper-bundle.min.css";
import webContent from "../include/Config";
// import Carousel from 'react-grid-carousel';
import { LazyLoadImage } from "react-lazy-load-image-component";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import axios from "axios";
import { Link } from "react-router-dom";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";

export const TourWidgets = () => {
  const [tourpackage, setTourPackage] = useState([]);
  const tourPackageList = async () => {
    axios
      .post(
        webContent.webLink + "/api/tourPackage.php",
        { SUBMIT: "TOURPACKAGE", LIMIT: 5 },
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((res) => {
        
        console.log(res.data);
        setTourPackage(res.data);
      })
      .catch((e) => {
        console.log(`register error ${e}`);
        //setIsLoading(false);
      });
  };

  // const settings = {
  //   centerMode: true,
  //   centerPadding: "10px",
  //   slidesToShow: 3,
  //   speed: 500,
  //   slidesToScroll: 3,
  //   arrows: false,
  //   dots: true,
  //   responsive: [
  //     {
  //       breakpoint: 768,
  //       settings: {
  //         arrows: false,
  //         slidesToShow: 3,
  //       },
  //     },
  //     {
  //       breakpoint: 480,
  //       settings: {
  //         arrows: false,
  //         slidesToShow: 3,
  //       },
  //     },
  //   ],
  // };

  const settings = {
    dots: true,
    arrows: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 3,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  useEffect(() => {
    tourPackageList();
  }, []);
  return (
    <>
      <section class="trending bg-grey pt-10 pb-6">
        <div
          class="section-shape top-0"
          style={{ backgroundImage: "url(images/shape8.png)" }}
        ></div>
        <div class="container">
          <div class="row align-items-center justify-content-between mb-6">
            <div class="col-lg-7">
              <div class="section-title text-center text-lg-start">
                <h4 class="mb-1 theme1">Top Tour Places</h4>
                <h2 class="mb-1">
                  Best <span class="theme">Tour Packages</span>
                </h2>
              </div>
            </div>
            <div class="col-lg-5"></div>
          </div>
          <div class="trend-box">
            <Slider {...settings} autoplay={true}>
              {tourpackage.length > 0 &&
                tourpackage.map((tourObj, index) => (
                  <div class="trend-item rounded box-shadow bg-white">
                    <div class="trend-image position-relative">
                      <LazyLoadImage
                        height="240px"
                        effect="blur"
                        src={
                          webContent.webLink +
                          "/assets/tourpackage/" +
                          tourObj?.tourpac?.images
                        } // use normal <img> attributes as props
                        width="100%"
                      />
                      <div class="color-overlay"></div>
                    </div>
                    <div class="trend-content p-4 pt-5 position-relative">
                      <div class="trend-meta bg-theme white px-3 py-2 rounded">
                        <div class="entry-author">
                          <i class="icon-calendar"></i>
                          <span class="fw-bold">
                            {" "}
                            {tourObj.tourpac.duration}
                          </span>
                        </div>
                      </div>
                      <h5 class="theme mb-1">
                        <i class="flaticon-location-pin"></i> [India]
                      </h5>
                      <h3 class="mb-1">
                        <Link
                          to={"/tourpackdetails"}
                          state={{ tourDetailsView: tourObj }}
                         
                        >
                          {tourObj.tourpac.title == ''
                            ? "UNTITLE"
                            : tourObj.tourpac.title}
                        </Link>
                      </h3>
                      <div class="rating-main d-flex align-items-center pb-2">
                        <div class="rating">
                          <span class="fa fa-star checked"></span>
                          <span class="fa fa-star checked"></span>
                          <span class="fa fa-star checked"></span>
                          <span class="fa fa-star checked"></span>
                          <span class="fa fa-star checked"></span>
                        </div>
                      </div>
                      <p class="border-b pb-2 mb-2">
                        {tourObj.tourpac.description.substring(0, 150)}
                      </p>
                      <div class="entry-meta">
                        <div class="entry-author d-flex align-items-center">
                          <p class="mb-0">
                            <span class="theme fw-bold fs-5">
                              <i className="fa fa-inr"></i>{" "}
                              {tourObj.tourpac.tour_price}.00
                            </span>{" "}
                            | Per person
                          </p>
                        </div>
                      </div>
                      <br />
                      <Link
                        to={"/tourpackdetails"}
                        state={{ tourDetailsView: tourObj }}
                        class="nir-btn"
                      >
                        View Tour Package
                      </Link>
                      {/* <div class="entry-meta">
                      <div class="entry-author d-flex align-items-center">
                        <p class="mb-0">
                          <span class="theme fw-bold fs-5"> $ {tourObj.tourDetails.tour_price}</span> | Per
                          person
                        </p>
                      </div>
                    </div> */}
                    </div>
                  </div>
                ))}
            </Slider>
          </div>
          <div class="category-main-inner">
            <div class="row align-items-center">
              <div class="col-lg-2">
                <h3 class="mb-0 white bg-theme p-3 py-5 text-center rounded my-4">
                  Top Categories
                </h3>
              </div>
              <div class="col-lg-10">
                <Slider {...settings} autoplay={true}>
                  <div class="category-item box-shadow pb-2 text-center bg-white rounded overflow-hidden">
                    <div class="trending-topic-content">
                      <img
                        src="images/categori/camping.jpg"
                        class="mb-1 d-inline-block"
                        alt=""
                      />
                      <h4 class="mb-0">
                        <Link to="#">Camping</Link>
                      </h4>
                    </div>
                  </div>

                  <div class="category-item box-shadow pb-2 text-center bg-white rounded overflow-hidden">
                    <div class="trending-topic-content text-center">
                      <img
                        src="images/categori/hiking.jpg"
                        class="mb-1 d-inline-block"
                        alt=""
                      />
                      <h4 class="mb-0">
                        <Link to="#">Hiking</Link>
                      </h4>
                    </div>
                  </div>

                  <div class="category-item box-shadow pb-2 text-center bg-white rounded overflow-hidden">
                    <div class="trending-topic-content">
                      <img
                        src="images/categori/paragliding.jpg"
                        class="mb-1 d-inline-block"
                        alt=""
                      />
                      <h4 class="mb-0">
                        <Link to="#">Paragliding</Link>
                      </h4>
                    </div>
                  </div>

                  <div class="category-item box-shadow pb-2 text-center bg-white rounded overflow-hidden">
                    <div class="trending-topic-content">
                      <img
                        src="images/categori/safari.jpg"
                        class="mb-1 d-inline-block"
                        alt=""
                      />
                      <h4 class="mb-0">
                        <Link to="#">Safari</Link>
                      </h4>
                    </div>
                  </div>
                </Slider>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
