import React, { useEffect, useState } from "react";
import webContent from "../include/Config";
import axios from "axios";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, A11y, EffectCube, Autoplay } from "swiper";
import "swiper/swiper-bundle.min.css";
import { Link, NavLink } from "react-router-dom";
export const Footer = () => {
  const [tourimg, setTourImages] = useState([]);
  const tourImg = async () => {
    axios
      .post(
        webContent.webLink + "/api/tour.php",
        { SUBMIT: "TOUR", LIMIT: 3 },
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((res) => {
        console.log(res.data);
        setTourImages(res.data);
      })
      .catch((e) => {
        console.log(`register error ${e}`);
        //setIsLoading(false);
      });
  };

  useEffect(() => {
    tourImg();
  }, []);
  return (
    <>
      <footer
        className="pt-20 pb-4"
        style={{ backgroundImage: "url(images/background_pattern.png)" }}
      >
        <div
          className="section-shape top-0"
          style={{ backgroundImage: "url(images/shape8.png)" }}
        ></div>

        <div className="insta-main pb-10">
          <div className="container">
            <div className="insta-inner">
              <Swiper
                className="row attract-slider"
                breakpoints={{
                  576: {
                    width: 576,
                    slidesPerView: 1,
                  },
                  768: {
                    width: 768,
                    slidesPerView: 3,
                  },
                }}
                modules={[Navigation, A11y, EffectCube, Autoplay]}
                loop={false}
                autoplay={{
                  delay: 3000,
                  disableOnInteraction: false,
                }}
                spaceBetween={20}
                //slidesPerView={3}
                navigation={{
                  nextEl: ".clients-next",
                  prevEl: ".clients-prev",
                }}
                pagination={{ clickable: true }}
                //scrollbar={{ draggable: false }}
                onSlideChange={() => console.log("slide change")}
                onSwiper={(swiper) => console.log(swiper)}
                effect={"slide"}
                cubeEffect={{
                  shadow: false,
                  slideShadows: false,
                  shadowOffset: 20,
                  shadowScale: 0.94,
                }}
              >
                {tourimg.length > 0 &&
                  tourimg.map((tourImgObj, index) => (
                    <SwiperSlide className="col-md-3 col-sm-6">
                      <div className="insta-image rounded">
                        <Link to={"/tourdetails"}
                              state={{ tourInfo: tourImgObj}}>
                          <img
                            src={
                              webContent.webLink +
                          "/assets/tour/" +
                          tourImgObj?.tourImages[0]?.images
                            }
                          />
                        </Link>
                        <div style={{position:"absolute",bottom: 0,padding: "10px",color: "#fff"}}>{tourImgObj.tourDetails.title.toUpperCase()}</div>
                      </div>
                    </SwiperSlide>
                  ))}
              </Swiper>
              
            </div>
          </div>
        </div>

        <div className="footer-upper pb-4">
          <div className="container">
            <div className="row">
              <div className="col-lg-4 col-md-6 col-sm-12 mb-4 pe-4">
                <div className="footer-about">
                  <img src="images/logo.png" alt="" />
                  <p className="mt-3 mb-3 white">
                    Welcome to [Siliguri Luxury Cab Service], your trusted
                    partner in creating unforgettable travel experiences!
                  </p>
                  <ul>
                    <li className="white">
                      <strong>PO Box:</strong> +91-7602628549 / 7908833511{" "}
                    </li>
                    <li className="white">
                      <strong>Location:</strong> Champasari, Samar Nagar,
                      Siliguri-734008
                    </li>
                    <li className="white">
                      <strong>Email:</strong>
                      <a
                        className="__cf_email__"
                        data-cfemail="83eaede5ecc3d7f1e2f5e6efeaedade0ecee"
                      >
                        susantabhowmick19810@gmail.com
                      </a>
                    </li>
                    <li className="white">
                      <strong>Website:</strong> siliguriluxurycabservice.com
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-2 col-md-6 col-sm-12 mb-4">
                <div className="footer-links">
                  <h3 className="white">Quick link</h3>
                  <ul>
                    <li>
                      <NavLink to="/abuoutus">About Us</NavLink>
                    </li>

                    <li>
                      <NavLink to="/privacypolicy">Privacy Policy</NavLink>
                    </li>
                    <li>
                      <NavLink to="#">Terms &amp; Conditions</NavLink>
                    </li>
                    <li>
                      <NavLink to="#">Customer Service</NavLink>
                    </li>
                    <li>
                      <NavLink to="#">Return Policy</NavLink>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-2 col-md-6 col-sm-12 mb-4">
                <div className="footer-links">
                  <h3 className="white">Categories</h3>
                  <ul>
                    <li>
                      <NavLink to="#">Travel</NavLink>
                    </li>
                    <li>
                      <NavLink to="#">Lifestyle</NavLink>
                    </li>
                    <li>
                      <NavLink to="#">Destinations</NavLink>
                    </li>
                    <li>
                      <NavLink to="#">Entertainment</NavLink>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
                <div className="footer-links">
                  <h3 className="white">Newsletter</h3>
                  <div className="newsletter-form">
                    <p className="mb-3">
                      Join our community of over 200,000 readers who receives
                      emails filled with tour details, promotions, and offers
                      etc.
                    </p>
                    <form
                      action="#"
                      method="get"
                      accept-charset="utf-8"
                      className="border-0 d-flex align-items-center"
                    >
                      <input type="text" placeholder="Email Address" />
                      <button className="nir-btn ms-2">Subscribe</button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="footer-copyright">
          <div className="container">
            <div className="copyright-inner rounded p-3 d-md-flex align-items-center justify-content-between">
              <div className="copyright-text">
                <p className="m-0 white">
                  2023 siliguriluxurycabservice.com. All rights reserved. Design
                  by NETDEMI.COM
                </p>
              </div>
              <div className="social-links">
                <ul>
                  <li>
                    <a href="#">
                      <i className="fa fa-facebook" aria-hidden="true"></i>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="fa fa-twitter" aria-hidden="true"></i>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="fa fa-instagram" aria-hidden="true"></i>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="fa fa-linkedin" aria-hidden="true"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div id="particles-js"></div>
      </footer>

      <div id="back-to-top">
        <a target="_blank" href="https://api.whatsapp.com/send?phone=+918101638997&text=I am interested"><i class="fa fa-whatsapp" aria-hidden="true"></i></a>
      </div>

      <div id="back-to-two-top">
        <a target="_blank" href="https://api.whatsapp.com/send?phone=+919883457992&text=I am interested"><i class="fa fa-whatsapp" aria-hidden="true"></i></a>
      </div>

      <div id="search1">
        <button type="button" className="close">
          x
        </button>
        <form>
          <input type="search" value="" placeholder="type keyword(s) here" />
          <button type="submit" className="btn btn-primary">
            Search
          </button>
        </form>
      </div>
    </>
  );
};
