import React, { useEffect, useState } from "react";
import webContent from "../include/Config";
import axios from "axios";
import { Modal } from "react-responsive-modal";
import { Link } from "react-router-dom";
import { CarQueryWidgets } from "../popup/CarQueryWidgets";
import { TourDetailsLeft } from "../widgets/TourDetailsLeft";
import { OurPartner } from "../widgets/OurPartner";
export const SelfDrive = () => {
  const [vec, setVec] = useState([]);
  const [open, setOpen] = useState(false);
  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);
  const [vecDetails, setVacDetails] = useState([]);
  const vecList = async () => {
    axios
      .post(
        webContent.webLink + "/api/selfdrive.php",
        { SUBMIT: "SELFDRIVE", LIMIT: 100 },
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((res) => {
        //console.log(res.data);
        setVec(res.data);
      })
      .catch((e) => {
        console.log(`register error ${e}`);
        //setIsLoading(false);
      });
  };

  useEffect(() => {
    vecList();
  }, []);
  return (
    <>
      <section
        class="breadcrumb-main pb-20 pt-14"
        style={{ backgroundImage: "url(images/bg/bg1.jpg)" }}
      >
        <div
          class="section-shape section-shape1 top-inherit bottom-0"
          style={{ backgroundImage: "url(images/shape8.png)" }}
        ></div>
        <div class="breadcrumb-outer">
          <div class="container">
            <div class="breadcrumb-content text-center">
              <h1 class="mb-3">OUR SELF DRIVE CAR</h1>
              <nav aria-label="breadcrumb" class="d-block">
                <ul class="breadcrumb">
                  <li class="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>
                  <li class="breadcrumb-item active" aria-current="page">
                    List Of Self Drivr Car
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
        <div class="dot-overlay"></div>
      </section>

      <section class="blog trending">
        <div class="container">
          <div class="row flex-row-reverse">
            <div class="col-lg-12 ps-lg-4">
              <div class="listing-inner">
                <div class="row">
                  <div class="col-lg-12">
                    <div class="list-results d-flex align-items-center justify-content-between">
                      <div class="list-results-sort">
                        <p class="m-0">Showing {vec.length} results</p>
                      </div>
                    </div>
                  </div>

                  <table class="table table-bordered">
                    <thead>
                      <tr>
                        {/* <th>HOTEL LOCATION</th> */}
                        <th>VEHICLE NAME</th>
                        <th>VEHICLE LINK</th>
                        <th>VEHICLE SEATER</th>
                        <th>&nbsp;</th>
                      </tr>
                    </thead>
                    <tbody>
                      {vec.length > 0 &&
                        vec.map((tourVecObj, index) => (
                          <tr>
                            {/* <td>{imgObj.hotelDetails?.name}</td> */}
                            <td style={{ fontSize: "25px", color: "#029e9d" }}>
                              {tourVecObj.vecDetails.v_name} |{" "}
                              {tourVecObj.vecDetails.v_type}
                            </td>
                            <td>
                              <img
                                style={{ width: "200px" }}
                                src={
                                  webContent.webLink +
                                  "/assets/vehicle/" +
                                  tourVecObj?.vecImages[0]?.images
                                }
                                alt="image"
                                class=""
                              />
                            </td>

                            <td style={{ fontSize: "25px", color: "#029e9d" }}>
                              {tourVecObj.vecDetails.v_seat}
                            </td>
                            <td>
                              <Link
                                onClick={() => {
                                  onOpenModal(true);
                                  setVacDetails(tourVecObj);
                                }}
                                class="nir-btn"
                              >
                                BOOK NOW
                              </Link>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>

                  <Modal open={open} onClose={onCloseModal} center>
                    <CarQueryWidgets state={{ vehicalDetails: vecDetails }} />
                  </Modal>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
